<template>
  <div
    v-loading="loading"
    :class="taskTableData.length > 0 ? '' : 'mt-20'"
    class="weChat-push"
  >
    <div class="center_2">
      <!-- 推送详情 -->
      <div class="first-title mb-20">微信传播数据概览</div>
      <ul class="ul_0">
        <li class="li_1">
          <p class="cole_c5463" v-text="netWorkStatistics.networkCount" />
          <p>微网刊访问总次数</p>
        </li>
        <li class="li_2">
          <p class="col_0ba9e1" v-text="netWorkStatistics.articleCount" />
          <p>文章阅读总次数</p>
        </li>
        <li class="li_3">
          <p class="col_2db897" v-text="netWorkStatistics.userCount" />
          <p>授权用户总数</p>
        </li>
      </ul>
      <div class="conter_3 mb-3 mt-20">
        <div class="first-title fl mb-20">微网刊列表</div>
        <div
          v-if="taskTableData.length > 0"
          class="list_btn_setting"
          @click="copyDirURlModal = true"
        >
          设置
        </div>
        <commonTable
          ref="taskList"
          :choose-id="chooseWeChatId"
          :table-data="taskTableData"
          :column-data="taskColumn"
          :init-obj="netWorkPageObj"
          class="mb-20"
          show-list-btn
          no-serial
          show-choose
          show-handle
          show-preview
          is-click
          handle-content="预览"
          max-height="386px"
          @onRowClick="handleRowClick"
          @onHandlePreviewURL="previewURL"
          @onHandlePreviewJump="previewjump"
          @onChangePageData="
            (pageObj) => {
              getNetWorkArticles(pageObj)
            }
          "
        />
      </div>
      <!--文章阅读量排行-->
      <div class="mb-20 conter_3">
        <div class="first-title mb-20">文章阅读量排行</div>
        <commonTable
          :table-data="articleTableData"
          :column-data="articleColumn"
          :init-obj="articlePageObj"
          :is-click="false"
          @onChangePageData="
            (pageObj) => {
              getArticleListData(pageObj)
            }
          "
          @onClickTitle="handleOpenUrl"
        />
      </div>
      <!--学者活跃度排行-->
      <div class="conter_3">
        <div class="first-title mb-20">用户活跃度排行</div>
        <commonTable
          :table-data="authorTableData"
          :column-data="authorColumn"
          :init-obj="authorPageObj"
          :is-click="false"
          @onChangePageData="
            (pageObj) => {
              getUserAccess(pageObj)
            }
          "
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order)
            }
          "
        />
      </div>
    </div>
    <!-- 当期目录和历史目录的URL -->
    <el-dialog
      custom-class="urlDialog"
      :visible.sync="copyDirURlModal"
      title="设置"
      width="600px"
    >
      <div>
        <div style="margin-bottom: 20px">
          <el-row>
            <el-col :span="5" style="line-height: 66px">最新目次URL</el-col>
            <el-col :span="19">
              <el-input
                v-model="newDirURL"
                :autosize="{ minRows: 2, maxRows: 4 }"
                disabled
                type="textarea"
                placeholder="请输入内容"
                style="word-break: break-all"
              />
              <el-row type="flex" justify="end">
                <el-button
                  type="text"
                  @click="copyURL(newDirURL)"
                  data-track="增值服务-主动传播-微信传播-最新目次URL-复制链接"
                  >复制链接</el-button
                >
                <el-popover
                  placement="left"
                  width="172"
                  trigger="click"
                  @show="showURl('new')"
                >
                  <div class="btn_fx">
                    <el-button
                      size="yl"
                      round
                      @click="prviewNewUrl"
                      data-track="增值服务-主动传播-微信传播-最新目次URL-预览"
                      >预览</el-button
                    >
                    <div class="btn_fxd">分享到</div>
                    <div id="qrcodeNewURl" />
                  </div>
                  <el-button
                    slot="reference"
                    type="text"
                    data-track="增值服务-主动传播-微信传播-最新目次URL-预览"
                    >预览</el-button
                  >
                </el-popover>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="mb-20">
          <el-row>
            <el-col :span="5" style="line-height: 66px">往期目次URL</el-col>
            <el-col :span="19">
              <el-input
                v-model="oldDirURL"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                disabled
                placeholder="请输入内容"
                style="word-break: break-all"
              />
              <el-row type="flex" justify="end">
                <el-button
                  type="text"
                  @click="copyURL(oldDirURL)"
                  data-track="增值服务-主动传播-微信传播-往期目次URL-复制链接"
                  >复制链接</el-button
                >
                <el-popover
                  placement="left"
                  width="172"
                  trigger="click"
                  @show="showURl('old')"
                >
                  <div class="btn_fx">
                    <el-button
                      size="yl"
                      round
                      @click="prviewOldUrl"
                      data-track="增值服务-主动传播-微信传播-往期目次URL-预览"
                      >预览</el-button
                    >
                    <div class="btn_fxd">分享到</div>
                    <div id="qrcodeOldURl" />
                  </div>
                  <el-button
                    slot="reference"
                    type="text"
                    data-track="增值服务-主动传播-微信传播-往期目次URL-预览"
                    >预览</el-button
                  >
                </el-popover>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <!-- <div class="mb-20">
          <el-row>
            <el-col :span="5" style="line-height: 66px">图文消息模板</el-col>
            <el-col :span="19">
              <a
                :href="pictureTextUrl"
                target="blank"
                style="
                  color: #333;
                  text-decoration: underline;
                  word-break: break-all;
                "
                data-track="增值服务-主动传播-微信传播-图文消息模板"
                >{{ pictureTextUrl }}</a
              >
            </el-col>
          </el-row>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from '@comp/commonTable'
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import { WeChatAllJumpUrl } from '@assets/js/utils.js'
import qrcodejs2 from 'qrcodejs2'
const api = ProjectConfig.weChatPush
export default {
  name: 'PushResults',
  components: {
    commonTable
  },
  data() {
    return {
      loading: true,
      // 当前选中的微网刊Id
      chooseWeChatId: '',
      taskTableData: [], // 推送任务列表
      pushStatusObj: {
        0: '待发送',
        1: '推送中',
        2: '已试推送',
        3: '已推送'
      },
      taskInfo: {},
      currentRow: [],
      radio: 0,
      total3: 100,
      // 文章阅读量排行列表
      articleTableData: [],
      articleColumn: [
        {
          title: '标题',
          prop: 'title',
          special: true,
          width: '300px',
          notSortable: true,
          clickParam: 'htmlUrl'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keyWords',
          notSortable: true
        },
        {
          title: '阅读量',
          prop: 'networkCount',
          notSortable: true
        }
      ],
      articlePageObj: {
        page: 1,
        size: 10
      },
      authorPageObj: {
        page: 1,
        size: 10
      },
      taskColumn: [
        {
          title: '微网刊标题',
          prop: 'sysTopic',
          notSortable: true,
          width: '250px'
        },
        {
          title: '发布时间',
          prop: 'sysCreated',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'year',
          notSortable: true,
          width: '60px'
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true,
          width: '60px'
        },
        {
          title: '微网刊访问次数',
          prop: 'netWorkCount',
          notSortable: true,
          width: '140px'
        },
        {
          title: '文章阅读次数',
          prop: 'articleCount',
          notSortable: true
        },
        {
          title: '访问用户数（已授权）',
          prop: 'userCount',
          notSortable: true,
          width: '180px'
        }
      ],
      netWorkPageObj: {
        page: 1,
        size: 10,
        sort: 'year,issue,desc'
      },
      authorTableData: [],
      authorColumn: [
        {
          title: '微信昵称',
          prop: 'nickName',
          notSortable: true
        },
        {
          title: '学者地区',
          prop: 'province',
          notSortable: true
        },
        {
          title: '阅读文章次数',
          prop: 'articleCount',
          notSortable: true
        }
      ],
      netWorkInitObj: {
        page: 1,
        size: 12
      },
      netWorkStatistics: {
        userCount: 0,
        articleCount: 0,
        networkCount: 0
      },
      isShowMore: false, // 是否显示更多任务列表
      dialogPushVisible: false,
      userNumber: '', // 推送用户

      netWorkTotal: 0,
      netWorkCurrentPage: 1,
      netWorkPageSize: 10,
      copyDirURlModal: false,
      templateType: null,
      taskId: null,
      sortValue: ''
    }
  },
  computed: {
    ...mapState('producePlantForm', ['magazineId']),
    ...mapState('incrementService', ['magId']),
    newDirURL() {
      return WeChatAllJumpUrl(this.magId)
    },
    oldDirURL() {
      return `http://www.das.publish.founderss.cn/otherPages/Microblog/html/catalog.html?taskId=${this.taskId}&magId=${this.magId}`
    },
    pictureTextUrl() {
      let url =
        window.location.origin +
        '/service/precisionPush/pictrueTextMsg?columnId=3-1'
      return url
    }
  },
  watch: {
    magId(n) {
      if (n) {
        this.getNetWorkArticles()
        this.getNetworkStatistics()
        this.getArticleListData()
        this.getUserAccess()
      } else {
        this.initData()
      }
    }
  },
  mounted() {
    if (this.magId) {
      this.getNetWorkArticles()
      this.getNetworkStatistics()
      this.getArticleListData()
      this.getUserAccess()
    } else {
      this.loading = false
      this.initData()
    }
  },
  methods: {
    initData() {
      this.taskTableData = []
      this.$set(this.netWorkStatistics, 'networkCount', 0)
      this.$set(this.netWorkStatistics, 'articleCount', 0)
      this.$set(this.netWorkStatistics, 'userCount', 0)
      this.articleTableData = []
      this.authorTableData = []
    },
    // 获取历史所有微网刊
    getNetWorkArticles(pageParams = {}) {
      const { page = 1, size = 10, sort = 'year,issue,desc' } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort,
        isPublish: 1, // 1是已发布  0是未发布
        isShow: false
      }
      let url = api.GetNetWorkArticles.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.netWorkPageObj = {
            ...this.netWorkPageObj,
            page,
            size,
            total: totalElements
          }
          this.taskTableData = content
          if (
            this.taskTableData &&
            this.taskTableData.length &&
            this.taskTableData.length > 0
          ) {
            this.$nextTick(() => {
              this.$refs.taskList.handleRowClick(this.taskTableData[0])
            })
          } else {
            this.chooseWeChatId = ''
            this.articleTableData = []
            this.authorTableData = []
          }
        }
      })
    },
    // 点击每一行
    handleRowClick(id, item) {
      this.chooseWeChatId = id
      this.handleCurrentChange(item)
    },
    // 获取访问量
    getNetworkStatistics() {
      const params = {
        magId: this.magId
      }
      let url = api.GetNetworkStatistics.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          if (res.data.data) {
            this.netWorkStatistics = res.data.data
          }
        }
      })
    },
    // 获取文章阅读量排行
    getArticleListData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        networkId: this.chooseWeChatId
      }
      let url = api.GetArticleAccess.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.articlePageObj = {
            page,
            size,
            total: totalElements
          }
          this.articleTableData = content
        }
      })
    },
    // 获取学者活跃度
    getUserAccess(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        networkId: this.chooseWeChatId
      }
      let url = api.GetUserAccess.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.authorPageObj = {
            page,
            size,
            total: totalElements
          }
          this.authorTableData = content
        }
      })
    },
    // 排序的改变了
    handleChangeSort(prop, order) {
      this.sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          this.sortValue = prop + ',asc'
        } else {
          this.sortValue = prop + ',desc'
        }
      }
      this.getUserAccess()
    },
    // 跳转页面
    handleOpenUrl(chooseItem, columnItem) {
      const { clickParam = '' } = columnItem
      if (clickParam && clickParam !== '' && chooseItem[clickParam] !== '') {
        if (chooseItem[clickParam]) {
          window.open(chooseItem[clickParam])
        }
      }
    },
    // 推送结果选中行改变
    handleCurrentChange(val) {
      // 选中行
      if (val) {
        // 设置radio选中
        this.currentRow = val
        const { id } = val
        this.chooseWeChatId = id
        this.radio = id
        this.templateType = val.templateType
        this.taskId = val.id
        this.getArticleListData()
        this.getUserAccess()
      }
    },
    // 复制链接
    copyURL(data) {
      var oInput = document.createElement('input')
      oInput.value = data
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.$message.closeAll()
      this.$message({
        message: '复制成功!',
        type: 'success'
      })
    },
    // 表格的预览
    previewURL(value) {
      document.getElementById(`qrcode${value.id}`).innerHTML = ''
      let qrcode = new qrcodejs2(`qrcode${value.id}`, {
        width: 120,
        height: 120,
        colorDark: '#000',
        colorLight: '#fff'
      })
      qrcode.makeCode(WeChatAllJumpUrl(this.magId, value.id))
    },
    // 表格预览跳转
    previewjump(value) {
      window.open(WeChatAllJumpUrl(this.magId, value.id))
    },
    showURl(type) {
      let idNode = type === 'new' ? 'qrcodeNewURl' : 'qrcodeOldURl'
      document.getElementById(idNode).innerHTML = ''
      let qrcode = new qrcodejs2(idNode, {
        width: 120,
        height: 120,
        colorDark: '#000',
        colorLight: '#fff'
      })
      if (type === 'new') {
        qrcode.makeCode(this.newDirURL)
      } else {
        qrcode.makeCode(this.oldDirURL)
      }
    },
    prviewNewUrl() {
      window.open(this.newDirURL)
    },
    prviewOldUrl() {
      window.open(this.oldDirURL)
    }
  }
}
</script>

<style scoped lang="scss">
.weChat-push {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  .urlDialog {
    .el-button--text {
      background: #fff;
    }
  }
  .conter_3 .mb-20 {
    // margin-bottom: 20px !important;
    // margin: 0px -10px 20px 0px !important;
  }
  .fl {
    float: left;
  }
  .mt-20 {
    margin-top: 20px;
  }
}
.first-title {
  text-align: left;
  position: relative;
  text-indent: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  line-height: 14px;
}
.first-title::before {
  content: ' ';
  position: absolute;
  width: 3px;
  height: 14px;
  background: #3e55ad;
  top: 0;
  left: 0;
}
.list_btn_task {
  cursor: pointer;
  padding-left: 22px;
  display: inline-block;
  color: #2644ca;
  line-height: 36px;
  background: url('../../../assets/img/icon_btn_list.png') left center no-repeat;
}
.list_btn_setting {
  cursor: pointer;
  padding-left: 22px;
  display: inline-block;
  color: #2644ca;
  line-height: 21px;
  background: url('../../../assets/img/icon_btn_setting.png') left center
    no-repeat;
  background-size: 18px 18px;
  margin-right: 20px;
  position: absolute;
  right: 0;
  right: 110px;
  font-size: 14px;
}
.pushAgain {
  color: #999;
  text-decoration: underline;
}
.pushAgain:hover {
  color: #2644ca;
}
.taskTableDataList {
  z-index: 99;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.25);
  width: 100%;
}
/deep/.taskTableDataList {
  .el-table__empty-block {
    min-height: 40px;
  }
  .el-table__empty-text {
    line-height: 40px;
  }
}
.conter_4 {
  display: flex;
}
.conter_4 > ul {
  box-sizing: border-box;
  border-right: 1px solid #eaeaea;
}
.conter_4 > ul > li {
  font-size: 14px;
  color: #999;
  text-align: left;
  line-height: 30px;
  display: flex;
}
.conter_4 > ul > li > p {
  width: 140px;
}
.conter_4 > ul > li > span {
  color: #666;
}
.conter_4 .ul_1 > li > span {
  color: #265cca;
  display: inline-block;
  text-align: left;
  width: 100%;
}
.ul_1 {
  width: 400px;
}
.conter_4 .ul_1 > li > p {
  width: 140px;
}
.ul_2 {
  width: 380px;
  padding-left: 30px;
}
.conter_4 > ul.ul_3 {
  flex: 1;
  padding-left: 30px;
  border: none;
}
.center_table {
  position: absolute;
  top: 0px;
  right: 0;
  padding: 10px;
  width: 510px;
  height: 450px;
}
.map_table {
  position: relative;
  background: #265cca;
}
.postion {
  position: relative;
}
.setChartData {
  position: absolute;
  top: 70px;
  left: 50%;
  margin-left: -78px;
  z-index: 100;
}
.ul_0 {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #999;
  text-align: left;
  line-height: 30px;
  background: #f5f7fd;
  padding: 14px 0;
}
.ul_0 li p {
  line-height: 24px;
  text-align: center;
  margin: 0;
}
.ul_0 li p.cole_c5463 {
  line-height: 28px;
  font-size: 22px;
  color: #ec5463;
}
.ul_0 li p.col_0ba9e1 {
  line-height: 28px;
  font-size: 22px;
  color: #0ba9e1;
}
.ul_0 li p.col_2db897 {
  line-height: 28px;
  font-size: 22px;
  color: #2db897;
}
.postionMap {
  position: absolute;
  top: 12px;
  left: 12px;
}
.btn.postionMap .el-radio-button {
  top: 0;
  margin-right: 10px;
}
.chooseChart3Time {
  position: absolute;
  top: 15px;
  right: 0;
}
.chooseChart3Time label:nth-child(1) {
  margin-right: 20px;
}
.clearfix .noData {
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #edeff7;
  color: #909399;
}
/deep/ .countTable {
  .el-table thead.is-group th,
  .el-table__header-wrapper tbody td,
  .el-table th.is-leaf,
  .el-table__footer-wrapper tbody td {
    background-color: #f1f2f4 !important;
    border-right: 1px solid #e1e3e4;
    border-bottom: 1px solid #e1e3e4;
  }
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #e1e3e4;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #e1e3e4;
  }
  .el-table__footer-wrapper tbody td {
    border-top: 1px solid #e1e3e4;
    border-bottom: 1px solid #e1e3e4;
  }
  .el-table .el-table__header thead th .cell {
    display: inline;
  }
}
.dialogPushVisible {
  background: rgba(0, 0, 0, 0.3) !important;
}
.dialogPushVisible .pushDialog button {
  color: #265cca;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}
.shangc h2 {
  margin-bottom: 10px;
}
.shangc p {
  line-height: 30px;
}
.shangc p a {
  color: #265cca;
}
.choose-style {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
  box-sizing: initial;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  .child {
    border-radius: 50%;
    background: white;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 33%;
    left: 38%;
    box-sizing: initial;
  }
}
.choose-style:hover {
  border: 1px solid #2644ca;
}
.is-choose-style {
  background: #2644ca;
}

.qr-wrapper {
  padding: 0 10px;
}
.btn_fx {
  padding: 14px 26px;
  text-align: center;
}
.btn_fxd {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0;
}
.btn_fx2 {
  text-align: center;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-36 {
  margin-top: 36px;
}
</style>
<style lang="scss">
.weChat-push {
  ul,
  li,
  p {
    margin: 0;
    padding: 0;
  }
  .el-textarea.is-disabled .el-textarea__inner {
    cursor: default !important;
  }
  .list-btn {
    line-height: 21px !important;
  }
  .urlDialog {
    margin-top: 0 !important;
  }
}
.net-work-page {
  padding: 0 30px;
  .el-select {
    line-height: 28px !important;
  }
}
.el-popover .btn_fx img {
  min-width: auto;
}
</style>
